import { template as template_81a568ec008e4c6985e02cc28a41f53a } from "@ember/template-compiler";
const FKControlMenuContainer = template_81a568ec008e4c6985e02cc28a41f53a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
