import { template as template_2caf280f20234d45bb48a217c0535936 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_2caf280f20234d45bb48a217c0535936(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
