import { template as template_e3ea633a14a8435283a3a29a71ae88b3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_e3ea633a14a8435283a3a29a71ae88b3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
