import { template as template_e2c9fd8c2d2347eebb031654b733345d } from "@ember/template-compiler";
const FKLabel = template_e2c9fd8c2d2347eebb031654b733345d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
